.chat-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5f7;
  opacity: 0.2;
  background-image: radial-gradient(#1f01b9 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
}
