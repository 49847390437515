@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .flex_center {
    @apply tw-flex tw-items-center tw-justify-center;
  }

  .page_container {
    @apply tw-p-2;
  }
  .circle-bg-hover {
    @apply tw-cursor-pointer tw-rounded-full flex_center hover:tw-bg-slate-200;
  }
  .custom-input {
    @apply tw-border-solid tw-bg-gray-50 tw-border focus:tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5;
  }
  .absolute_center {
    @apply tw-absolute tw-top-[50%] tw-left-[50%] tw-translate-y-[-50%] tw-translate-x-[-50%];
  }
}

button {
  border: none;
  background: none;
}
p {
  margin-bottom: 0;
}

* {
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
span {
  display: inline-block;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --main: #2001b9;
  --white-second: #f7e7e6;
}
.main-bg {
  background-color: var(--main);
}

/* SideBar.css */
.sidebar {
  position: fixed;
  /* min-height: 100vh; 56px is the default height of the Navbar, adjust as needed */
  background-color: #2001b9; /* Set your preferred background color */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a subtle effect */
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.bg-main-color {
  background-color: #2001b9;
}
.text-main-color {
  color: #2001b9;
}
.text-white-color {
  color: white;
}
.page-header {
  color: #2001b9;
}
.nav-pills .nav-link {
  color: var(--white-second);
  border-radius: 0%;
  font-size: 13px;
}

.nav-pills .nav-link:hover {
  background-color: var(--white-second);
  color: var(--main);
}

/* tutor info */
.user-info {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.info-row {
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
}

.info-value {
  margin-left: 10px;
}

/* Additional styles can be added as needed */
